import { Alert, Button, Form, Input, InputNumber, Radio, RadioChangeEvent, Spin, Tooltip, Typography } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import TextArea from 'antd/lib/input/TextArea'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { messageError } from '../Message'
import { myBalanceFetch } from '../../reducers/user'
import { postNewCodes } from '../../reducers/submitCodes'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

const { Title } = Typography

export interface SubmitFormData {
  platform: string
  group: string
  codes: string
  notes: string | any,
  customRate: number
}

const SubmitCodesForm = ( { 
  filterCardsWithNoData, 
  selectedPlatform, 
  isLoading, 
  codeFormatTypes, 
  showRate, 
  instructions, 
  currency,
  usdRates,
  countryCurrencies,
  platformVariants,
  selectedVariant,
  setSelectedVariant,
  curCardValues,
  setCurCardValues,
  curRate,
  setCurRate
 } ) => {

  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const [curCountryValue, setCurCountryValue] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [amount, setAmount] = useState(100);
  const [showDenominations, setShowDenominations] = useState(false);

  const handleChangeFilter = useCallback((e: RadioChangeEvent) => {
    const [curCountryValue, curRate, ...rest] = e.target.value.split('-')
    setCurCountryValue(curCountryValue);
    setCurRate(curRate);
    const curCardValues = rest.join('-').trim();
    const cardValuesArray = curCardValues.split(',');
    setCurCardValues(cardValuesArray);
    
    setShowDenominations(true);
  }, []);

  const currentUsdRate = useMemo(
    () => 
    selectedPlatform === 'Steam' ?

     usdRates && usdRates?.['USD']

     : curCountryValue.length === 3 ? 
     usdRates?.[curCountryValue] 
     : usdRates?.[countryCurrencies[curCountryValue]],
    [usdRates, selectedPlatform, curCountryValue, countryCurrencies]
  );

  const handleSuccess = useCallback(
    (data: SubmitFormData) => {
      setIsProcessing(true)
      // console.log("SUBMITDATA", data)
      const { codes, notes = "", customRate } = data
      const codesArray: string[] = codes.split('\n')
                                   .map((item: string) => item.trim())
                                   .filter((item: string) => item !== "");

      dispatch(postNewCodes(selectedVariant, curCountryValue, codesArray, notes, customRate / 100))
        .then((res: any) => {
          const result = res
          
          if (result.error) {
            messageError(result.error)
            setIsProcessing(false)
          } else {
          //@ts-ignore
              window.dataLayer.push({ event: 'Submit_complete'})
              if (typeof window !== 'undefined' && typeof window.fbq === 'function') {
                window.fbq('track', 'Lead');
              }
              dispatch(myBalanceFetch())
                .then((res) => {
                  localStorage.setItem('balance', JSON.stringify(res))
                })
                .catch((err) => {
                  messageError(err)
                })
              !result.error && setTimeout(() => { 
              navigate(`/my-codes/${result.codePackId}`, { replace: true })
              setIsProcessing(false)
              }, 3000)
          }
        })
        .catch((error: any) => {
          if (error?.message?.includes("request header 'Authorization'") || error?.includes("request header 'Authorization'")) {
            messageError(`Authorization expired, please log in again`)
            setIsProcessing(false)
            window.location.reload();
          } else {
            messageError(`Can't submit codes. ${error.message || error}`)
            setIsProcessing(false)
          }
        })
    },
    [navigate, dispatch, selectedPlatform, curCountryValue]
  )

  const sortedCards = filterCardsWithNoData[selectedVariant]?.toSorted((a, b) => a.groupDisabled - b.groupDisabled)

  return (
    
    <Form
    onFinish={handleSuccess} 
    className={styles.inputs}
    >
      <div className={styles.menuGroup}>
                <ul className={styles.menu}>
                  <li className={styles.menuCurrent}>
                    <a href="#" data-hover={selectedPlatform.startsWith('R-') ? selectedPlatform === "R-Hotels" ? `${selectedPlatform.slice(2)}.com` : selectedPlatform.slice(2) : selectedPlatform}>
                    {selectedPlatform.startsWith('R-') ? selectedPlatform === "R-Hotels" ? `${selectedPlatform.slice(2)}.com` : selectedPlatform.slice(2) : selectedPlatform}
                    </a>
                    </li>
              </ul>
              
              <span>{selectedPlatform.startsWith('R-') ? 'non-instant' : 'instant'} code verification</span>
              {selectedPlatform === 'Steam' && <span>We accept all currencies</span>}
              </div>
              {platformVariants.length > 1 && 
              <>
              <span style={{opacity: '0.5'}}>Type</span>
              <div className={styles.radioGroup}>
              
                {isLoading ? (
                  <Spin />
                ) : (
                  <Radio.Group
                    onChange={(e) => setSelectedVariant(e.target.value)}
                    value={selectedVariant}
                    optionType="button"
                    buttonStyle="solid"
                  >
                    
                    {platformVariants &&
                      platformVariants?.map((option: any, index) => (
                        <Radio.Button
                        name="group"
                        value={option}
                        // value={value}
                        key={index}
                        className={styles.radioGroupInst}
                        // disabled={isPlatformValid}
                        >
                          <p>{option === "R-XBox-Pass" ? "Xbox Game Pass" : option === "R-XBox-Ult" ? "Game Pass (Ultimate)" : option === "R-XBox-Core" ? "Game Pass (Other)" : option.slice(2)}</p>
                        </Radio.Button>
                      ))}
                  </Radio.Group>
                )}
              </div>
              </>}


              <span style={{opacity: '0.5'}}>Currencies</span>
              <div className={styles.radioGroup}>
                {isLoading ? (
                  <Spin />
                ) : (
                  
          <Radio.Group
                    onChange={handleChangeFilter}
                    optionType="button"
                    buttonStyle="solid"
                    value={curCountryValue && curRate ? `${curCountryValue}-${curRate}-${curCardValues}` : null}
                    >
            {sortedCards?.map(({ group, rate, cardValues, groupDisabled }, index) => (
              groupDisabled ? <Tooltip title="Temporarily unavailable">
                <Radio 
              name="group" 
              className={styles.radio} 
              value={`${group}-${rate}-${cardValues}`}
              key={index}
              disabled
              >
                {(selectedVariant === 'R-XBox-Ult' || selectedVariant === 'R-XBox-Core') ? group === 'DE' ? 'EU' : group : group} 
                    <br />
                    <small>Rate: {rate ? (rate * 100).toFixed(0) : 'n/a'}%</small>
              </Radio>
              </Tooltip> :
              <Radio 
              name="group" 
              className={styles.radio} 
              value={`${group}-${rate}-${cardValues}`}
              key={index}
              >
                {(selectedVariant === 'R-XBox-Ult' || selectedVariant === 'R-XBox-Core') ? group === 'DE' ? 'EU' : group : group} 
                    <br />
                    <small>Rate: {rate ? (rate * 100).toFixed(0) : 'n/a'}%</small>
              </Radio>
            ))}
          </Radio.Group>
                )}
                <br />
                </div>

                <div style={{marginBottom: '18px'}}>
                {curRate ? (
                  <div className={styles.calculatorWrapper}>
                    <span style={{opacity: '0.5'}}>Rates Calculator</span>
                <div className={styles.calculator}>
                
                <InputNumber addonBefore='Amount' onChange={setAmount} value={amount} />
                <InputNumber addonBefore={'$'} disabled value={`${currentUsdRate ? (amount * curRate * currentUsdRate).toFixed(2) : 'n/a'}`}/>
                </div>
                </div>
                ) : ( buttonDisabled ? <Alert type='warning' message="We aren't accepting codes in this group at the moment!"/> : <Alert message='Choose currency to calculate'/>)}
              </div>

              <div style={{marginBottom: '18px'}}>
                <div>
                <span style={{opacity: '0.5', marginRight: '8px'}}>Accepted denominations</span>
                {curCardValues.length > 0 && (
                  showDenominations === true ?
                  <Tooltip title="Hide denominations">
                  <EyeOutlined onClick={() => setShowDenominations(false)}/>
                  </Tooltip>  
                  : <Tooltip title="Show denominations">
                    <EyeInvisibleOutlined onClick={() => setShowDenominations(true)}/>
                    </Tooltip>
                  )}
                
                {curCardValues.length > 0 ? (
                  showDenominations &&
                  <div className={styles.denominationsWrapper}>
                  {curCardValues?.toSorted((a, b) => {

                      let numA = parseInt(a.split(' ')[0], 10);
                      let numB = parseInt(b.split(' ')[0], 10);
                      
                      let isANumeric = !isNaN(numA);
                      let isBNumeric = !isNaN(numB);
                      
                      if (isANumeric && isBNumeric) {
                        return numA - numB;
                      }
                      
                      if (isANumeric) return -1;
                      if (isBNumeric) return 1;
                      
                      return 0;
                    })?.map((denomination, index) => (
                    denomination !== null && <span className={styles.denominations} key={`${denomination}-${index}`}>{denomination === "null" ? "all" : denomination}</span>
                ))}
                </div>) : ( <div className={styles.denominationsAlert}><Alert message='Choose currency to see accepted denominations'/></div>) }
                
                </div>
              </div>

                <Form.Item
                name="codes"
                rules={[
                  {
                    required: true,
                    message:  <span style={{ color: 'red' }}>Please enter codes!</span>,
                  },
                ]}
              >
                  <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 10 }} placeholder="Enter up to 20 codes here, one per line" />
              </Form.Item>

                <div className={styles.instructions}>
                <span>{instructions}</span>
                </div>

                <Form.Item name="notes">
                  <TextArea rows={1} placeholder="Add note" autoSize={false} maxLength={40} />
                </Form.Item>

                {showRate &&
                <Form.Item name="customRate">
                  <Input placeholder="Add rate" />
                </Form.Item>
                }

                <Form.Item>
                {selectedPlatform.startsWith('R-') ? <span className={styles.buttonText}>{codeFormatTypes?.["Non-Instant"]}</span> 
                : <span className={styles.buttonText}>{codeFormatTypes?.["Instant"]}</span>}
                  <div className={styles.submitButtonWrapper}>
                <Button aria-label="Submit codes button" className={styles.button} type="primary" htmlType="submit" disabled={isProcessing || buttonDisabled}>
                {isProcessing ? `Submitting...` : 'Submit'}
                </Button>
                </div> 
                
              </Form.Item>
      </Form>
  )
}

export default SubmitCodesForm
