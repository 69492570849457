import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import CustomImage from '../CustomImage'
import { Divider, Skeleton } from 'antd'
import { shallowEqual, useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { fetchContacts } from '../../reducers/noAuth'
import { getDomainUrl } from 'front-end-common/src/utils/envDependent'
import { extractDomainName, extractDomainURL, isClapswapFlow } from '../../utils/extractDomain'

function Footer() {

  const { payload, isLoading } = useSelector((state) => state?.noAuthReducer?.contacts, shallowEqual)

  const { contacts } = payload || {}

  const { telegram, whatsApp } = contacts || {}

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchContacts())
  }, [])

  return (
    <div style={{maxWidth: '1280px', margin: '24px auto', width: '85vw'}}>
    <div className={styles.footerContainer}>
    <Divider />
    <footer className={styles.footerNew}>
            <div className={styles.footerClapswap}>
            <p>&copy; {`${new Date().getFullYear()} ${extractDomainURL(getDomainUrl())}`}</p>
            </div>
            <div className={styles.footerLinks}>
            {isClapswapFlow() &&
            whatsApp ? <p>WhatsApp: <a href={`https://api.whatsapp.com/send?phone=${whatsApp}`} rel="noopener noreferrer" target="_blank">{whatsApp}</a></p> 
            : ""
            }
            {telegram ? 
            <p>Telegram: <a href={`https://t.me/${telegram}`} rel="noopener noreferrer" target="_blank">{telegram}</a> </p> 
            : ""
            }
            </div>
            <small style={{color: '#808080'}}>{extractDomainName(getDomainUrl())} has no relation to Google Play, Valve,  Vanilla, Best Buy, Footlocker, Gamestop, Netflix, Nike, Nordstrom, Sephora, OffGamers, eBay, Amazon, Razer, Target, Walmart, XBOX, Microsoft, MoneyGram, Western Union, Payoneer, WorldRemit, Paxum, PayPal, Amazon, OkPay, Payza, Walmart, Reloadit, Perfect Money, WebMoney, Google Wallet, BlueBird, Serve, Square Cash, NetSpend, Chase QuickPay, Skrill, Vanilla, MyVanilla, OneVanilla, Neteller, Venmo, Apple, ChimpChange or any other payment method. We make no claims about being supported by or supporting these services.
            Their respective wordmarks and trademarks belong to them alone. All rights reserved. v. 1.7 {' '}
            <a href="/privacy-policy">Privacy Policy</a>
        </small>
        </footer>
        </div>
        </div>
  )
}

export default Footer
